import Vue from 'vue'
import Router from 'vue-router'
import HomeApps from "../components/HomeApps.vue";
import GameOpen from "../components/GameOpen.vue";
import PrivacyPage from "../components/PrivacyPage.vue";

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/', component: HomeApps, name: 'goHome', props: true },
        { path: '/game/:language/:id', component: GameOpen, name: 'openGame', props: true },
        { path: '/privacy', component: PrivacyPage, props: true }
    ]
})