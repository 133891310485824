<template>
  <div id="app" class="app">
    <Header />
    <router-view />
    <!--<Footer />-->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import Vue from "vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import HomeApps from "./components/HomeApps.vue";
window.$ = window.jQuery = require("jquery");

export default {
  name: "App",
  props: [],
  components: {
    Header: Header,
    Footer: Footer,
    HomeApps: HomeApps,
  },
  data: function () {
    return {
      // paging vars
      page_start: 0,
      page_end: this.$store.state.games_page_limit,
      current_page: 1,
      //
      cloud: {},
      //
    };
  },
  computed: {
    ...mapState({
      googleFonts: (state) => state.googleFonts,
    }),
  },
  methods: {
    openEntryGame(id) {
      console.log("Opening entry game...");
      let game = this.allgames.filter((obj) => {
        return obj.id === id;
      })[0];
      this.$root.$emit("openGame", { game: game });
    },
    initLoad(source = "open", language = this.language) {
      var SELF = this;
      var entryPointData = {};
      if (source == "open") {
        // may be first load
        // normal game launching - direct open or shared open
        SELF.$store.commit("SET_ME"); // save user data to state storage
        entryPointData = FBInstant.getEntryPointData() || {}; // if shared open
      } else if (source == "change_lan") {
        this.$router.push({ name: "goHome" }).catch(() => {});
      }
      if (window.FBInstant_found) {
        FBInstant.player
          .getDataAsync(["played", "language"]) // get cloud user data
          .then(function (data) {
            SELF.cloud = data; // cloud user data
            if (entryPointData.lang) {
              // if got from shared game
              language = entryPointData.lang;
            } else if (SELF.cloud.language) {
              // if got from user cloud
              language = SELF.cloud.language;
            }
            /****************************************************************************************************** */
            if (SELF.allgames.length == 0 || language != SELF.language)
              SELF.$store
                .dispatch("GET_GAMES_ASYNC", { language: language })
                .then(
                  // fetch games
                  (response) => {
                    // RESPONSE OK
                    SELF.$store.commit("SET_LANGUAGE", language); // change on state store
                    SELF.$store.commit("SET_ALL_GAMES", {
                      // save current language game
                      games: response.data.reverse(),
                      language: language,
                      played: SELF.cloud.played,
                    });
                    /********************** */
                    if (entryPointData.id) {
                      // only work with first load and valid data
                      // goto target game
                      SELF.openEntryGame(entryPointData.id);
                    }
                    /********************** */
                    /*** BECAUSE WE NEED PAGING ***/
                    SELF.page_start = 0;
                    SELF.page_end = SELF.$store.state.games_page_limit;
                    let games = SELF.allgames.slice(
                      SELF.page_start,
                      SELF.page_end
                    );
                    //console.log("Start :" + self.page_start + " End : " + self.page_end);
                    SELF.$store.commit("SET_GAMES", {
                      games: games,
                      language: language,
                    }); // save curent language games to state storage
                    //console.log("App.vue -  initLoad completed");
                  },
                  (error) => {
                    // response error
                    console.log("Failed loading games..." + error);
                  }
                );
            /****************************************************************************************************** */
          });
      } else {
        /****************************************************************************************************** */
        if (SELF.allgames.length == 0 || language != SELF.language)
          SELF.$store.dispatch("GET_GAMES_ASYNC", { language: language }).then(
            // fetch games
            (response) => {
              // RESPONSE OK
              SELF.$store.commit("SET_LANGUAGE", language); // change on state store
              SELF.$store.commit("SET_ALL_GAMES", {
                // save current language game
                games: response.data.reverse(),
                language: language,
                played: SELF.cloud.played,
              });
              /********************** */
              if (entryPointData.id) {
                // only work with first load and valid data
                // goto target game
                SELF.openEntryGame(entryPointData.id);
              }
              /********************** */
              /*** BECAUSE WE NEED PAGING ***/
              SELF.page_start = 0;
              SELF.page_end = SELF.$store.state.games_page_limit;
              let games = SELF.allgames.slice(SELF.page_start, SELF.page_end);
              //console.log("Start :" + self.page_start + " End : " + self.page_end);
              SELF.$store.commit("SET_GAMES", {
                games: games,
                language: language,
              }); // save curent language games to state storage
              //console.log("App.vue -  initLoad completed");
            },
            (error) => {
              // response error
              console.log("Failed loading games..." + error);
            }
          );
        /****************************************************************************************************** */
      }
    },
    getPaging() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.current_page += 1;
          this.page_start = this.page_end;
          this.page_end = this.current_page * this.games_page_limit;
          //console.log("Start :" + this.page_start + " End : " + this.page_end);
          let games = this.allgames.slice(this.page_start, this.page_end);
          games.length > 0
            ? this.$store.commit("PUSH_TO_PAGE", {
                games: games,
              })
            : undefined; // change games on state store
        }
      };
    },
  },
  beforeCreate() {
    //console.log("App - Before Created !");
  },
  created() {
    //console.log("App - Created !");
    this.initLoad();
  },
  beforeMount() {
    //console.log("App - Before Mount !");
  },
  mounted() {
    //console.log("App - Mounted !");
    var self = this;
    /******************************************************************* */
    this.getPaging();
    this.$root.$on("openGame", (data) => {
      // OPEN GAME
      this.$router
        .push({
          name: "openGame",
          params: {
            game: data.game,
            id: data.game.id,
            language: this.language,
          },
        })
        .catch(() => {}); // open route
      this.$store.commit("GAME_OPENED", { game: data.game }); // change opened games position
      this.$store.commit("UPDATE_PLAYED", {
        // updated played list on local and cloud
        game: data.game,
        language: this.language,
      }); // push to played games and update cloud
      this.$store.commit("saveOpenedGame", { game: data.game }); // save to current open game
    });
    this.$root.$on("playAgain", (data) => {
      this.$router
        .push({
          name: "loadingGame",
          params: { game: data.game, id: data.game.id },
        })
        .catch(() => {}); // open route
      window.scrollToTop("smooth");
    });
    this.$root.$on("showLoading", (data) => {
      this.$router
        .push({
          name: "loadingGame",
          params: { game: data.game, id: data.game.id },
        })
        .catch(() => {}); // open route
    });

    this.$root.$on("shareNow", (base64, game) => {
      FBInstant.shareAsync({
        intent: "SHARE",
        image: base64,
        text: "",
        data: {
          lang: this.language,
          id: game.id,
          tag: "some_tag",
          timestamp: new Date().getTime(),
        },
      }).then(function () {
        window.scrollToId("popular-head", "smooth");
      });
    });
    // change language from header dropdown
    this.$root.$on("changeLang", (code) => {
      FBInstant.player.setDataAsync({ language: code }).then(function () {
        self.initLoad("change_lan", code);
      });
      self.initLoad("change_lan", code); // FOR LOCAL TESTING ONLY
    });
  },
  watch: {},
};

Vue.mixin({
  computed: {
    ...mapState({
      languages: (state) => state.languages,
      language: (state) => state.language,
      games_page_limit: (state) => state.games_page_limit,
      played: (state) => state.played,
      allgames: (state) => state.allgames,
      games: (state) => state.games,
      player: (state) => state.player,
      me: (state) => state.me,
    }),
    ...mapActions([
      "TEST", // map `this.increment()` to `this.$store.dispatch('increment')`
    ]),
  },
  data: function () {
    return {
      user: {
        id: null,
        name: null,
        avatar: null,
        avatarBase64: null,
        contextType: null,
      },
    };
  },
  methods: {},
  beforeMount() {},
  mounted() {},
  created() {},
  beforeCreate() {},
  watch: {},
});
</script>
<style>
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
#app {
  padding-top: 49px;
  font-family: "Roboto Condensed", Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: #1196b8;
  color: #fff;
  text-decoration: none;
}
body,
html {
  -ms-overflow-style: none;
  scrollbar-width: 5px;
}
::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #555555;
}
[v-cloak] {
  display: none;
}
</style>
