<template>
  <div class="homeApps" id="container-homeApps">
    <div v-if="games.length == 0" class="loading">
      <div
        class="
          d-flex
          flex-column
          min-vh-100
          justify-content-center
          align-items-center
        "
      >
        <img :src="require(`@/assets/loading balls.gif`)" width="100"/>
      </div>
    </div>
    <div id="games_container" class="container text-justify">
      <div class="row">
        <div
          class="col-12 col-sm-6 col-md-4 col-lg-4 game-container"
          v-for="(game, index) in pageGames"
          v-bind:index="index"
          v-bind:key="index"
          @click="routeOpenGame(game, index)"
        >
          <div class="game-wrapper" v-bind:class="{ 'first-game': index == 0 }">
            <div class="panel panel-primary">
              <div class="panel-body">
                <div class="game-new" v-if="game.hot">
                  <img v-lazy="require(`@/assets/fire.gif`)" />HOT
                </div>
                <div class="game-trending" v-if="game.trending">
                  <img v-lazy="require(`@/assets/heart.gif`)" />TRENDING
                </div>
                <img
                  v-lazy="require(`@/assets/malluapps/app${game.id}.jpg`)"
                  class="game-thumb"
                />
              </div>
              <div class="game-name-div">
                <div class="game-name">
                  {{ game.name }}
                  <span class="icon"
                    ><font-awesome-icon icon="caret-right"
                  /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "HomeApps",
  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      games: (state) => state.games,
    }),
    pageGames: function () {
      return this.games.filter((i) => i.id !== this.$route.params.id); // for hiding current game from page list
    },
  },
  methods: {
    routeOpenGame(game) {
      this.$root.$emit("openGame", { game: game, language: this.language });
      window.scrollToTop("instant");
    },
  },
  beforeCreate() {},
  beforeMount() {},
  created() {},
  mounted() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#container-homeApps {
  padding-top: 8px;
}
#container-homeApps .container {
  min-height: 100vh;
}
.game-thumb {
  border-bottom: none;
  width: 100%;
}
.game-container:hover {
  transform: translateY(3px);
  transition: all 0.3s ease;
}
.panel .icon {
  font-size: 1.3rem;
  float: right;
}
.panel:hover .icon {
  color: #208080;
}
.panel img {
  border-radius: 3px;
}
.panel-body {
  background-color: lavender;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.game-new {
  font-size: 10px;
  color: #ffffff;
  -webkit-text-stroke: 0.3px rgb(0, 0, 0);
  line-height: 9px;
  margin: 5px 0 0 0px;
  padding: 5px;
  position: absolute;
  top: 0;
  background-color: #000;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 8px;
  z-index: 2;
}
.game-trending {
  font-size: 10px;
  color: rgb(0, 0, 0);
  line-height: 9px;
  margin: 5px 0 0 0px;
  padding: 5px;
  position: absolute;
  top: 0;
  background-color: rgb(255, 255, 255);
  border-top-left-radius: 3px;
  border-bottom-right-radius: 8px;
  z-index: 2;
}
.game-container {
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
}
.game-wrapper {
  border: rgb(211, 67, 67) 5px solid;
  border-radius: 8px;
  margin-bottom: 8px;
}
.game-name-div {
  background-color: lavender;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 5px;
  color: #2a3542;
  min-height: 53px;
  font-size: 15px;
  font-weight: bold;
  text-align: justify !important;
}
.first-game {
  border: rgb(208, 211, 16) 5px solid;
}
.game-new img {
  width: 10px;
  padding-bottom: 3px;
  padding-right: 3px;
  padding-left: 0px;
}
.game-trending img {
  width: 12px;
  padding-bottom: 3px;
  padding-right: 3px;
  padding-left: 0px;
}
.loading {
  margin-left: auto;
  margin-right: auto;
  width: 30px;
}
</style>
