<template>
  <div id="privacy" class="privacy">
    <div id="privacy_container" class="container text-justify">
      Privacy Contents Here...
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: [],
  computed: {},
  methods: {},
  created: function () {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
