import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    id: 424526646062064,
    app: "MalluApps",
    language: "en", // current language
    openedGame: {},
    drawingGame: {},
    recommends: [], // recommended games
    friends: [], // user friends
    me: {}, // user data
    languages: [{ "id": "1", "code": "en", "name": "English", "native": "English", "priority": "1000", "active": "1", "share": "Share" }, { "id": "2", "code": "ml", "name": "Malayalam", "native": "മലയാളം", "priority": "2000", "active": "1", "share": "പങ്കിടുക" }, { "id": "3", "code": "ta", "name": "Tamil", "native": "தமிழ்", "priority": "3000", "active": "1", "share": "பகிர்" }],
    games: [], // games showing in page
    allgames: [], // all games by current language
    played: {}, // list of played games
    games_page_limit: 9, // limit of games in page
    settings: {
      "min_canvas_wait_time": 500
    },
    googleFonts: {
      // Malayalam
      "Manjari": "https://fonts.gstatic.com/s/manjari/v7/k3kQo8UPMOBO2w1UfcHoLnnA.woff2",
      "Gayathri": "https://fonts.gstatic.com/s/gayathri/v13/MCoQzAb429DbBilWLLAn-p7ABg.woff2",
      "Chilanka": "https://fonts.gstatic.com/s/chilanka/v16/WWXRlj2DZQiMJYaYRoJPKdAwZg.woff2",
      "Noto Sans Malayalam": "https://fonts.gstatic.com/s/notosansmalayalam/v13/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_RuDNG1bF.woff2",
      // Tamil
      "Noto Sans Tamil": "https://fonts.gstatic.com/s/notosanstamil/v14/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGo40AoqE.woff2",
      // Hindi
      "Noto Sans Devanagari": "https://fonts.gstatic.com/s/notosansdevanagari/v14/TuGOUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv1lXfxk.woff2"
    }
  },
  actions: {
    GET_GAMES_ASYNC(state, data) {
      //console.log(data)
      this.commit("EMPTY_GAMES"); // show blank while loading
      return new Promise((resolve, reject) => {
        // Do something here... lets say, a http call using vue-resource
        axios
          .get("languages/" + data.language + ".json").then(response => {
            // http success, call the mutator and change something in state
            resolve(response);  // Let the calling function know that http is done. You may send some data back
          }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
          })
      })
    },
    GET_DRAW_DATA(state, data) {
      //console.log(data)
      var id = data.game.id;
      return new Promise((resolve, reject) => {
        // Do something here... lets say, a http call using vue-resource
        let ext = ".txt"
        axios
          .get("data/" + id + ext).then(response => {
            resolve(response);
          }, error => {
            reject(error);
          })
      })
    }
  },
  mutations: {
    SET_ALL_GAMES(state, data) { // save all games by order of not played to played
      if (data.played) {
        state.played = data.played; // update local
        //console.log("played found")
        let fetchedGames = data.games;
        let notPlayedGames = {};
        let playedGames = {};
        if (data.played[data.language]) {
          /*** NOT PLAYED GAMES ***/
          notPlayedGames = fetchedGames.filter(function (objFromA) {
            return !data.played[data.language].find(function (
              objFromB
            ) {
              return objFromA.id === objFromB.id;
            });
          });
          /*** GET PLAYED GAMES ***/
          playedGames = fetchedGames.filter(function (objFromA) {
            return data.played[data.language].find(function (
              objFromB
            ) {
              return objFromA.id === objFromB.id;
            });
          });
          state.allgames = notPlayedGames; // first add not played games list
          state.allgames.push(...playedGames); // then push played games to last
        }
        else {
          state.allgames = data.games
        }
      }
      else {
        state.allgames = data.games
      }
    },
    EMPTY_GAMES(state) {
      state.games = []
    },
    PUSH_TO_PAGE(state, data) { // push new scrolled games
      state.games.push(...data.games);
    },
    // add to play object
    UPDATE_PLAYED(state, data) {
      state.played[data.language] ? undefined : state.played[data.language] = [] // created a played array
      if (state.played[data.language].filter(function (e) { return e.id === data.game.id; }).length > 0) { // found
        var index = state.played[data.language].map(function (e) { return e.id; }).indexOf(data.game.id); // played index
        state.played[data.language][index]['plays']++; // update play
        //console.log("Repeat Play !")
      }
      else {
        data.game.plays = 1;
        let test = { id: data.game.id, plays: data.game.plays }
        state.played[data.language].push(test);
        //console.log("First Play !")
      }
      let playedData = JSON.parse(JSON.stringify(state.played));
      //console.log(state.played)
      FBInstant.player
        .setDataAsync({
          played: playedData,
        })
        .then(function () {
          //console.log("Set Played Game !");
        });
      //console.log(state.allgames)
    },
    // add played game to last of lists & add push a new game to page last
    GAME_OPENED(state, data) {
      let gIndex = state.games.map(function (e) { return e.id; }).indexOf(data.game.id); // games array index
      let agIndex = state.allgames.map(function (e) { return e.id; }).indexOf(data.game.id); // allgames array index
      let gTemp = state.games[gIndex] // temp game element
      let agTemp = state.allgames[agIndex] // temp allgame element
      if (state.games.length == state.allgames.length) { // full game pages loaded
        //console.log("full")
        state.games.push(state.games.splice(gIndex, 1)[0]); // move to last
        state.allgames.push(state.allgames.splice(agIndex, 1)[0]); // move to last
      }
      else { // more games available to scroll
        //console.log("not full")
        state.games.splice(gIndex, 1); // remove from current loaded games
        state.allgames.push(state.allgames.splice(agIndex, 1)[0]); // move to last
        let next = state.allgames[state.games.length] // temp allgame element for fill empty space
        state.games.push(next); // push to last
      }
    },
    saveOpenedGame(state, data) {
      state.openedGame = data.game
      //console.log("Open Game : "+data.game.id);
    },
    saveDrawingGame(state, data) {
      state.drawingGame = data.game
      //console.log("Draw Game : "+data.game.id);
    },
    SET_ME: function (state) {
      state.me = {
        id: FBInstant.player.getID(),
        name: FBInstant.player.getName(),
        avatar: FBInstant.player.getPhoto(),
        entryPointData: FBInstant.getEntryPointData()
      }
      //console.log(state.me)
    },
    SET_LANGUAGE: function (state, code) {
      state.language = code // update local
      FBInstant.player.setDataAsync({ // update cloud
        language: code
      }).then(function () {
      });
    },
    SET_GAMES: function (state, data) {
      state.games = data.games
      window.scrollToId("app", "instant");
    },
  }
});