<template>
  <div>
    <div class="gameOpen" id="gameOpen" v-if="!this.loading && !this.result">
      <div class="container text-left">
        <div class="top">
          <p class="game-name">{{ this.currentGame.game.name }}</p>
          <div class="game-doit-container alert">
            <p class="game-doit">{{ this.currentGame.game.desc }}</p>
          </div>
        </div>
        <div class="text-center">
          <img
            v-lazy="
              require(`@/assets/malluapps/app${this.currentGame.game.id}.jpg`)
            "
            class="img-fluid"
            @click="calcGame(currentGame)"
          />
        </div>
        <div class="play-btn-div text-center">
          <button
            type="button"
            class="btn btn-outline-success btn-lg"
            @click="calcGame(currentGame)"
          >
            <span class="icon"><font-awesome-icon icon="gamepad" /></span
            >&nbsp;Play
          </button>
        </div>
      </div>
    </div>
    <div
      class="gameLoading"
      id="gameLoading"
      v-if="this.loading && !this.result"
    >
      <div class="container loading">
        <p class="game-name">
          {{ this.currentGame.game.name || "" }}
        </p>
        <img :src="require(`@/assets/loading.gif`)" class="img-fluid" />
        <section class="load-container">
          <div>Analysing ...</div>
          <div>Calculating Result...</div>
          <div>Result will shock you...</div>
        </section>
      </div>
    </div>
    <div class="gameResult" id="gameResult" v-show="this.result">
      <div class="container result text-center">
        <p class="game-hasht">
          <span
            v-for="tag in this.currentGame.game.tags"
            :key="tag"
            @click="langClick(lang.code)"
          >
            #{{ tag }}</span
          >
        </p>
        <p class="game-name">
          {{ this.currentGame.game.name || "" }}
        </p>
        <p class="game-desc">{{ this.currentGame.game.desc || "-" }}</p>
        <div class="fb-btn-container text-center">
          <button
            type="button"
            class="btn btn-fbshare btn-lg btn-block"
            @click="share()"
          >
            <span class="icon"
              ><font-awesome-icon :icon="['fab', 'facebook-square']" /> </span
            >{{ this.language.share }}
          </button>
        </div>
        <canvas id="result" class="img-fluid"></canvas>
        <img :src="this.base64" class="img-fluid d-none" />
        <div class="fb-btn-container">
          <button
            type="button"
            class="btn btn-fbshare btn-lg btn-block"
            @click="share()"
          >
            <span class="icon"
              ><font-awesome-icon :icon="['fab', 'facebook-square']" /> </span
            >{{ this.language.share }}
          </button>
        </div>
        <div class="try-btn-container">
          <button
            type="button"
            class="btn btn-light btn-md"
            @click="retryGame(currentGame)"
          >
            <span class="icon"><font-awesome-icon icon="sync" /></span>
            Play Again
          </button>
        </div>
      </div>
      <div id="popular-head">
        <div id="head-container" class="containerr">Play Trending</div>
      </div>
    </div>
    <HomeApps />
  </div>
</template>
<script>
import { mapState } from "vuex";
import HomeApps from "../components/HomeApps.vue";
export default {
  name: "contentOpen",
  props: [],
  data() {
    return {
      loading: false,
      result: false,
      base64: null,
      start: 0,
      end: 0,
      elapsed: 0,
      wait: 0,
      loadedImages: {}, // asyc save url images
      media: {}, // asyc save url images,
      drawData: {}, // store template dynamic data
      drawMatches: {}, // store template dynamic data
    };
  },
  computed: {
    ...mapState({
      drawingGame: (state) => state.drawingGame,
      openedGame: (state) => state.openedGame,
      me: (state) => state.me,
      googleFonts: (state) => state.googleFonts,
      lang_code: (state) => state.language,
      all_languages: (state) => state.languages,
      settings: (state) => state.settings,
    }),
    currentGame: function () {
      return this.$route.params || {};
    },
    language: function () {
      let found = this.all_languages.find((e) => e.code === this.lang_code);
      return found;
    },
  },
  components: {
    HomeApps,
  },
  methods: {
    retryGame(data) {
      this.base64 = null;
      this.loading = this.result = false;
      window.scrollToTop("smooth");
      this.$root.$emit("openGame", { game: data.game }); // ( game data,show game, show result)
    },
    share() {
      var canvas = document.getElementById("result");
      this.$root.$emit(
        "shareNow",
        canvas.toDataURL("image/jpeg", 1.0),
        this.currentGame.game
      );
    },
    calcGame() {
      window.scrollToTop("smooth");
      this.loading = true;
      this.$store.commit("saveDrawingGame", { game: this.currentGame.game }); // save to current open game
      this.$store.dispatch("GET_DRAW_DATA", this.currentGame).then(
        // fetch games
        (response) => {
          // RESPONSE OK
          //console.log(response.data)
          this.draw(response.data);
        },
        (error) => {
          // RESPONSE ERROR
          //console.log("Failed load draw data..." + error);
          this.$router.push({ name: "goHome" }).catch(() => {});
        }
      );
    },
    template(text) {
      var self = this;
      if (text) {
        text = String(text); // sometimes it may be a number because of dynamic rotate angle
        let regex = /\{[a-zA-Z_|0-9-]+\}/g; // {rand_num|rn1|-10|20}
        var holders = text.match(regex);
        if (holders !== null) {
          holders.forEach(function (holder) {
            var array = holder.replace(/[{}]/g, "").split("|");
            let job = array[0];
            if (job == "name") {
              // name holder {name}
              text = text.replace(holder, self.me.name || "Lorem ipsum");
            } else if (job == "matches") {
              // matched array data holder {matches|name|0}
              let objKey = array[1];
              let index = array[2];
              text = text.replace(holder, self.drawMatches[objKey][index]);
            } else if (job == "rand_num") {
              // random number holder {rand_num|DATA1|min|max}
              let min = parseInt(array[2]);
              let max = parseInt(array[3]);
              let dataName = array[1];
              let rand = String(
                Math.floor(Math.random() * (max - min + 1) + min)
              ); // convert to String because of 0
              self.drawData[dataName] = rand; // save for future
              text = text.replace(holder, rand);
            } else if (job == "rand_color") {
              let dataName = array[1];
              // random number holder {rand_color|DATA1}
              let letters = "0123456789ABCDEF";
              text = "#";
              for (var i = 0; i < 6; i++) {
                text += letters[Math.floor(Math.random() * 16)];
              }
              text = text.replace(holder, text);
              dataName && (self.drawData[dataName] = text); // save for future same color
            }
          });
        }
        // replace data holder with previously stored text template data
        let dataRegex = /\[\[[a-zA-Z_|0-9]+\]]/g; // [[DATA_1]][[DATA_2]]
        var dataHolders = text.match(dataRegex);
        if (dataHolders !== null) {
          dataHolders.forEach(function (holder) {
            let dataName = holder.replace(/[[[\]\]]/g, "");
            if (self.drawData[dataName])
              text = text.replace(holder, self.drawData[dataName]);
          });
        }
        return text;
      } else {
        return text;
      }
    },
    drawText(ctx, item) {
      var SELF = this;
      var text = SELF.template(item.text, item); // make meaningful
      var cords = item.cords;
      var stroke = item.stroke || undefined;
      var fontFamily = item.font.family || "Arial";
      if (fontFamily.startsWith("GF:")) {
        // if google font name identified (GF:) then remove identification string and keep font name
        fontFamily = fontFamily.substring(3);
      } else if (fontFamily.startsWith("LF:")) {
        //
      } else {
        //
      }
      ctx.save(); // start rotation
      ctx.textAlign = item.textAlign || "start"; // align
      ctx.textBaseline = item.textBaseline || "alphabetic"; // align
      ctx.fillStyle = item.fill.color || "#000"; // color
      ctx.font =
        (item.font.style || "normal") +
        " " +
        (item.font.weight || "normal") +
        " " +
        item.font.size +
        "px " +
        fontFamily; // size & font family
      if (item.mode == "multi_text") {
        // convert sentences to multi line test
        var fitWidth = item.maxWidth || 0;
        var lineHeight = item.lineheight || ctx.measureText("M").width; // height finding trick using M letter
        var x = item.cords.x;
        var y = item.cords.y;
        /*if (fitWidth <= 0) {
          ctx.fillText(text, x, y);
          return;
        }*/
        var words = text.split(" ");
        var currentLine = 0;
        var idx = 1;
        while (words.length > 0 && idx <= words.length) {
          var str = words.slice(0, idx).join(" ");
          var w = ctx.measureText(str).width;
          if (w > fitWidth) {
            if (idx == 1) {
              idx = 2;
            }
            if (item.stroke) {
              ctx.globalAlpha = item.stroke.globalAlpha
                ? item.stroke.globalAlpha
                : 1; // if transparency set for text stroke
              ctx.strokeText(
                words.slice(0, idx - 1).join(" "),
                x,
                y + lineHeight * currentLine,
                item.maxWidth
              );
            }
            ctx.globalAlpha = item.globalAlpha ? item.globalAlpha : 1; // if transparency set for text
            ctx.fillText(
              words.slice(0, idx - 1).join(" "),
              x,
              y + lineHeight * currentLine,
              item.maxWidth
            );
            currentLine++;
            words = words.splice(idx - 1);
            idx = 1;
          } else {
            idx++;
          }
        }
        if (idx > 0) {
          if (item.stroke) {
            ctx.globalAlpha = item.stroke.globalAlpha
              ? item.stroke.globalAlpha
              : 1; // if transparency set for text stroke
            ctx.strokeText(
              words.join(" "),
              x,
              y + lineHeight * currentLine,
              item.maxWidth
            );
          }
          ctx.globalAlpha = item.globalAlpha ? item.globalAlpha : 1; // if transparency set for text
          ctx.fillText(
            words.join(" "),
            x,
            y + lineHeight * currentLine,
            item.maxWidth
          );
        }
      } else {
        let rotate = item.rotate !== undefined ? SELF.template(item.rotate) : 0;
        ctx.translate(cords.x, cords.y);
        ctx.rotate(rotate * (Math.PI / 180));
        if (stroke) {
          ctx.globalAlpha = stroke.globalAlpha ? stroke.globalAlpha : 1; // if transparency set for text stroke
          ctx.strokeText(text, 0, 0, item.maxWidth);
        }
        ctx.globalAlpha = item.globalAlpha ? item.globalAlpha : 1; // if transparency set for text
        ctx.fillText(text, 0, 0, item.maxWidth);
      }
      ctx.restore();
    },
    shuffleMatches() {
      /*
      - this is used by multiple arrays with same length
      - shuffles all arrays using same order for all
      - so it  is useful for relatable data from same position of different arrays
      - can serve by using array name and random key value
      */
      var matchArray = arguments[0]; // matches data object - { key : [array]}
      var totalArrays = Object.keys(matchArray).length;
      // valid or not
      for (let index = 0; index < totalArrays; index += 1) {
        var array = matchArray[Object.keys(matchArray)[index]];
        if (!Array.isArray(array)) {
          throw new TypeError("Argument is not an array.");
        }
        if (index === 0) {
          var arrLength = array.length;
        } else if (arrLength !== array.length) {
          throw new RangeError("Array lengths do not match.");
        }
      }
      var round, temp;
      while (arrLength) {
        round = Math.floor(Math.random() * arrLength);
        arrLength -= 1;
        for (let argsIndex = 0; argsIndex < totalArrays; argsIndex += 1) {
          let key = Object.keys(matchArray)[argsIndex];
          temp = matchArray[key][arrLength];
          matchArray[key][arrLength] = matchArray[key][round];
          matchArray[key][round] = temp;
        }
      }
      this.drawMatches = matchArray; // save shuffled data for future use
    },
    draw(data) {
      try {
        // not sure its pure json file content or encrypted for server
        data = JSON.parse(
          this.$CryptoJS.AES.decrypt(data, "0534953").toString(
            this.CryptoJS.enc.Utf8
          )
        );
      } catch (e) {
        // maybe its pure json, do nothing
      }
      var SELF = this;
      this.start = Date.now();
      /********************************************* */
      data["matches"] && this.shuffleMatches(data["matches"]); // shuffle matches data if available
      /********************************************* */ // array for storing drawing images
      var drawImages = {}; // { "avatar": "http://","background": "bg/something.jpg","any.jpg":"http://fdf/dfdf"}
      data["draw"].forEach(function (item) {
        let media = item.media || undefined;
        if (media == "avatar") {
          drawImages["avatar"] =
            SELF.me.avatar || require("../assets/avatar.png");
        } else if (item.type == "background" && item.fill.style == "image") {
          drawImages[media] = require("../canvas/backgrounds/" + media); // key is media name, value is link
        } else if (item.type == "image") {
          media = SELF.template(media); // sometimes we use dynamic media templates
          if (item.mode != "url") {
            // local image
            drawImages[media] = require("../canvas/images/" + media); // key is media name, value is link
          } else {
            // not a local image
            drawImages[media] = media; // key is media name, value is link
          }
        }
      });
      /********************************************* */ // load images first
      var promiseImages = Object.keys(drawImages).map(function (key) {
        var prom = new Promise(function (resolve, reject) {
          var img = new Image();
          img.onload = function () {
            SELF.media[key] = img;
            resolve();
          };
          img.src = drawImages[key];
          img.crossOrigin = "anonymous";
        });
        return prom;
      });
      /********************************************* */
      Promise.all(promiseImages).then(() => {
        // loaded all images
        //console.log("Loaded images...");
        /******************************************* START DRAWING ***/
        //console.log("Rendering...");
        var c = document.getElementById("result");
        var ctx = c.getContext("2d");
        /************************************************ */ // MAKE DRAWING BACKGROUND
        var bgData = data["draw"].find((e) => e.type === "background");
        c.width = bgData.cords.w;
        c.height = bgData.cords.h;
        if (bgData.filter) {
          let filter = bgData.filter;
          /*
          "filter": {
                "blur": "4px",
                "grayscale": "100%",
                "brightness": 0.4,
                "contrast": "200%",
                "drop-shadow": "16px 16px 20px blue",
                "invert": "75%",
                "opacity": "25%",
                "saturate": "30%",
                "sepia": "60%"
            }
          */
          let filterString = "";
          Object.keys(filter).forEach(function (method) {
            filterString += method + "(" + filter[method] + ") ";
          });
          ctx.filter = filterString;
        }
        ctx.globalAlpha = bgData.globalAlpha || 1;
        if (bgData.fill.style == "color") {
          // backgorund color
          ctx.fillStyle = SELF.template(bgData.fill.color);
          ctx.fillRect(0, 0, c.width, c.height);
        } else if (bgData.fill.style == "image") {
          // background image
          ctx.drawImage(
            SELF.media[bgData.media],
            0,
            0,
            bgData.cords.w,
            bgData.cords.h
          );
        } else if (bgData.fill.style == "gradient") {
          // gradient pattern making
          let gradient;
          if (bgData.fill.mode == "radial") {
            gradient = ctx.createRadialGradient(
              bgData.cords.x0,
              bgData.cords.y0,
              bgData.cords.r0,
              bgData.cords.x1,
              bgData.cords.y1,
              bgData.cords.r1
            );
          } else if (bgData.fill.mode == "linear") {
            gradient = ctx.createLinearGradient(
              bgData.cords.x0,
              bgData.cords.y0,
              bgData.cords.x1,
              bgData.cords.y1
            );
          }
          bgData.colorstops.forEach(function (o) {
            gradient.addColorStop(o.offset, SELF.template(o.color));
          });
          ctx.fillStyle = gradient;
          ctx.fillRect(0, 0, bgData.cords.w, bgData.cords.h);
        }
        /************************************************ */ // loop through draw array except background type
        data["draw"].forEach(function (item) {
          ctx.filter = "none"; // reset if already set
          if (item.filter) {
            // apply filter
            /*
          "filter": {
                "blur": "4px",
                "grayscale": "100%",
                "brightness": 0.4,
                "contrast": "200%",
                "drop-shadow": "16px 16px 20px blue",
                "invert": "75%",
                "opacity": "25%",
                "saturate": "30%",
                "sepia": "60%"
            }
          */
            let filterString = "";
            Object.keys(item.filter).forEach(function (method) {
              filterString += method + "(" + item.filter[method] + ") ";
            });
            ctx.filter = filterString;
          }
          var colorstops = item.colorstops || undefined;
          var rectangle = item.rectangle || undefined;
          // fill
          var fillStyle = item.fill !== undefined ? item.fill.style : undefined; // fill type color, image or something
          var fillMode = fillStyle == "gradient" ? item.fill.mode : undefined; // only for gradient fill type
          var fillColor =
            fillStyle == "color" ? SELF.template(item.fill.color) : undefined; // fill color - if style is color
          var gradient;
          var cords = item.cords;
          var stroke = item.stroke || undefined;
          var drawImg;
          ctx.save();
          if (stroke) {
            // text or image stroke
            ctx.lineWidth = stroke.lineWidth || 50;
            stroke.lineDash ? ctx.setLineDash(stroke.lineDash) : undefined;
            ctx.strokeStyle = SELF.template(stroke.color); // dynamic color
          }
          if (fillStyle == "color") {
            ctx.fillStyle = SELF.template(fillColor);
          }
          if (item.type == "gradient") {
            /*************************************************************************** GRADIENT */
            // gradient pattern making
            if (style == "linear") {
              gradient = ctx.createLinearGradient(
                cords.x0,
                cords.y0,
                cords.x1,
                cords.y1
              );
            } else if (style == "radial") {
              gradient = ctx.createRadialGradient(
                cords.x0,
                cords.y0,
                cords.r0,
                cords.x1,
                cords.y1,
                cords.r1
              );
            }
            colorstops.forEach(function (o) {
              gradient.addColorStop(o.offset, o.color);
            });
            ctx.fillStyle = gradient;
            ctx.fillRect(
              rectangle.x0,
              rectangle.y0,
              rectangle.x1,
              rectangle.y1
            );
          } else if (item.type == "image") {
            /*************************************************************************** IMAGE */
            drawImg = SELF.media[SELF.template(item.media)];
            ctx.globalAlpha = item.globalAlpha || 1;
            if (item.shape == "circle") {
              // conver to circle
              ctx.beginPath();
              ctx.arc(cords.x, cords.y, cords.r, 0, Math.PI * 2, false);
              if (stroke) {
                stroke.globalAlpha && (ctx.globalAlpha = stroke.globalAlpha); // if transparency set for stroke
                ctx.stroke();
              }
              ctx.clip();
              ctx.globalAlpha = item.globalAlpha ? item.globalAlpha : 1; // if transparency set for image
              ctx.drawImage(
                drawImg,
                cords.x - cords.r,
                cords.y - cords.r,
                cords.r * 2,
                cords.r * 2
              );
            } else {
              ctx.drawImage(drawImg, cords.x, cords.y, cords.w, cords.h);
              stroke && ctx.strokeRect(cords.x, cords.y, cords.w, cords.h);
            }
          } else if (item.type == "text") {
            /*************************************************************************** TEXT */
            SELF.drawText(ctx, item);
          } else if (item.type == "shape") {
            /*************************************************************************** SHAPES */
            ctx.globalAlpha = item.globalAlpha || 1;
            ctx.beginPath();
            let shape = item.shape;
            if (shape == "circle") {
              ctx.arc(cords.x, cords.y, cords.r, 0, 2 * Math.PI);
            } else if (shape == "square") {
              /*
              "cords": {
                "x": 50,
                "y": 200,
                "r": 100
              }
              */
              ctx.moveTo(cords.x, cords.y);
              ctx.lineTo(cords.x + cords.s, cords.y);
              ctx.lineTo(cords.x + cords.s, cords.y + cords.s);
              ctx.lineTo(cords.x, cords.y + cords.s);
            } else if (shape == "rectangle") {
              /*
              "cords": {
                "x": 50,
                "y": 200,
                "w": 300,
                "h": 150
              }
              */
              ctx.moveTo(cords.x, cords.y);
              ctx.lineTo(cords.x + cords.w, cords.y);
              ctx.lineTo(cords.x + cords.w, cords.y + cords.h);
              ctx.lineTo(cords.x, cords.y + cords.h);
            } else if (shape == "polygon") {
              /*
              "cords": {
                "x": 400, // start x
                "y": 0, // start y
                "lineTo": [ // each corner points
                    {
                        "x": 800,
                        "y": 0
                    },
                    {
                        "x": 800,
                        "y": 420
                    },
                    {
                        "x": 200,
                        "y": 420
                    }
                ]
              }
              */
              var lineTo = cords.lineTo;
              ctx.moveTo(cords.x, cords.y);
              lineTo.forEach(function (lineTo) {
                ctx.lineTo(lineTo.x, lineTo.y);
              });
            }
            ctx.closePath();
            ctx.fill();
            stroke && ctx.stroke(); // stroke only if defined
          }
          ctx.restore();
        });
        let dataUrl = c.toDataURL();
        /******************************************* DRAWING END ***/
        // custom wait time
        this.end = Date.now();
        this.elapsed = this.end - this.start;
        this.wait = this.settings.min_canvas_wait_time - this.elapsed;
        setTimeout(() => {
          this.base64 = dataUrl;
        }, this.wait);
      });
    },
  },
  beforeMount() {
    /*console.log(this.$route.params.language);
    console.log(this.$route.params.id);
    if (this.$route.params.language != this.language) {
      console.log("Load language first !");
    } else {
      console.log("nothing to do with lang");
    }*/
    if (this.currentGame.game) {
      //console.log("have");
    } else {
      //console.log("not have");
      this.$router.push({ name: "goHome" }).catch(() => {});
    }
  },
  beforeCreate() {},
  created() {
    var SELF = this;
    var fonts = SELF.googleFonts;
    for (var key in fonts) {
      // load fonts
      if (Object.prototype.hasOwnProperty.call(fonts, key)) {
        let myFont = new FontFace(key, "url(" + fonts[key] + ")");
        myFont.load().then(function (font) {
          // with canvas, if this is ommited won't work
          document.fonts.add(font);
          //console.log(fonts[key] + " Font loaded !");
        });
      }
    }
  },
  mounted() {},
  watch: {
    base64: function () {
      // canvas changed
      if (
        this.currentGame.game.id == this.drawingGame.id &&
        this.base64 != null
      ) {
        // check result is for opened game or not
        this.result = true;
        //console.log("Result Generated ✅");
      } else {
        //console.log("Different FOUND !");
      }
      window.scrollToTop("smooth");
    },
    $route() {
      // some other game clicked, so route changed
      //console.log(this.$route.params.language);
      //console.log(this.$route.params.id);
      this.base64 = null;
      this.loading = this.result = false;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  padding: 10px;
}
.gameOpen .container {
  background-color: #ffffff;
  border-radius: 0;
  padding: 0px;
}
.gameOpen img {
  border: lightcoral 5px solid;
  border-radius: 8px;
}
.gameOpen .container img {
  margin-top: 0px;
}
.gameOpen .container .game-name {
  color: rgb(22, 42, 59);
  text-align: left;
  font-size: 20px;
  margin-bottom: 0.5rem;
  line-height: 0.8em;
}
.gameOpen .container .game-doit-container {
  text-align: center;
  background-color: #1196b8;
  padding: 0.3rem 0.35rem;
}
.gameOpen .container .game-doit-container:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  width: 0;
  height: 0;
  border-top: solid 20px #1196b8;
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
}
.gameOpen .container .game-doit {
  color: #fff;
  margin-bottom: 0;
  font-size: large;
  line-height: 1em;
}
.play-btn-div {
  margin: 10px 0px;
  padding-bottom: 10px;
}
.play-btn-div .btn {
  color: #fff;
  border-color: #fff;
  background-color: #696969;
}

#gameLoading .container {
  padding: 10px;
  text-align: center;
  background: linear-gradient(359deg, #1196b8, #1196b8, #fff, #fff);
  background-size: 800% 800%;

  -webkit-animation: AnimationName 9s ease infinite;
  -moz-animation: AnimationName 9s ease infinite;
  animation: AnimationName 9s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
.gameLoading .container .game-name {
  color: black;
  text-align: center;
  font-size: 25px;
  margin-bottom: 0.1rem;
}
.load-container {
  margin-top: 50px;
  width: 100%;
  height: 220px;
  position: relative;
}

.load-container > div {
  font-size: 25px;
  color: #000;
  opacity: 0;
  height: 0px;
}
.load-container > div:nth-child(1) {
  animation: animation 6s infinite;
}
.load-container > div:nth-child(2) {
  animation: animation 6s infinite;
  animation-delay: 2s;
}
.load-container > div:nth-child(3) {
  animation: animation 6s infinite;
  animation-delay: 4s;
}
@keyframes animation {
  0% {
    opacity: 0;
    height: auto;
  }
  16% {
    opacity: 1;
  }
  33% {
    opacity: 0;
    height: 0px;
  }
  100% {
    opacity: 0;
    height: 0px;
  }
}

#gameResult .container {
  background-color: rgb(221, 220, 220);
  border-radius: 0;
  padding: 0px;
  margin-bottom: 0;
}
.gameResult .result.container .game-hasht {
  text-align: left;
  color: #898f9c;
  font-size: 1.5em;
  font-weight: 400;
  padding: 0 8px;
  margin-bottom: 0px;
}
.gameResult .result.container .game-name {
  color: #000;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  padding: 0 8px;
  line-height: 1em;
  margin-bottom: 0px;
}
.gameResult .result.container .game-desc {
  text-align: left;
  font-size: 1em;
  font-weight: 400;
  padding: 0 8px;
  color: rgb(26, 25, 25);
}
.btn-fbshare {
  background-color: #3b5998;
  color: #fff;
}
.fb-btn-container {
  text-align: center;
  margin: 10px 45px 10px 45px;
}
.fb-btn-container .icon {
  margin-right: 1rem;
}
.try-btn-container {
  padding-bottom: 10px;
}
.try-btn-container .btn {
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.063),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.07),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.07),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.066),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.062),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}
.try-btn-container .icon {
  margin-right: 0.3rem;
}
#result {
  border: 1px #fff solid;
  background-color: #fff;
}

#head-container {
  background-color: black;
  color: #dddcdc;
  padding: 8px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  position: relative;
  margin-bottom: 15px;
}
.containerr:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  width: 0;
  height: 0;
  border-top: solid 20px #000;
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
}
</style>
