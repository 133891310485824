<template>
  <!-- Header -->
  <nav class="container-sm navbar navbar-expand-md fixed-top navbar-dark">
    <a class="navbar-brand" @click="goHome()">
      <img
        v-lazy="require(`@/assets/`+language+`malluapps-100x300.png`)"
        class="navbar-brand-logo"
        alt="logo"
      />
    </a>
    <div class="btn-group">
      <button
        class="btn btn-secondary btn-sm dropdown-toggle caret-off"
        type="button"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <font-awesome-icon icon="language" size="2x" />
      </button>
      <div class="dropdown-menu dropdown-menu-right">
        <a
          class="dropdown-item"
          :class="{ active: lang.code == language }"
          href="#"
          v-for="lang in languages"
          :key="lang.id"
          @click="langClick(lang.code)"
        >
          {{ lang.native }}</a
        >
        <!--
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#">Featured</a>
        <a class="dropdown-item" href="#" @click="resetPlayed()"
          >Reset Played Cloud</a
        >
        <a class="dropdown-item" href="#" @click="resetLang()"
          >Reset Language Cloud</a
        >
        <a class="dropdown-item" href="#" @click="resetCloud()">Reset Cloud</a>
        -->
      </div>
    </div>
    <!--<div class="btn-group" v-if="data.loading == true">
      &nbsp;<font-awesome-icon icon="spinner" size="lg" spin />
    </div>-->
  </nav>
  <!-- Header End -->
</template>
<script>
export default {
  name: "Header",
  props: [],
  methods: {
    langClick(code) {
      this.$root.$emit("changeLang", code);
    },
    goHome(code) {
      this.$router
        .push({
          name: "goHome",
        })
        .catch(() => {});
      window.scrollToTop("instant");
    },
    resetCloud() {
      FBInstant.player
        .setDataAsync({
          played: null,
          language: null,
        })
        .then(function () {
          console.log("RESET - CLOUD !");
        });
    },
    resetPlayed() {
      FBInstant.player
        .setDataAsync({
          played: null,
        })
        .then(function () {
          console.log("RESET - PLAYED !");
        });
    },
    resetLang() {
      FBInstant.player
        .setDataAsync({
          language: null,
        })
        .then(function () {
          console.log("RESET - LANGUAGE !");
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-brand-logo {
  height: 35px;
  cursor: pointer;
}
.navbar {
  background-color: #1196b8;
  padding: 0.15rem;
}
.navbar-nav {
  margin-left: auto;
}
.caret-off::before {
  display: none;
}
.caret-off::after {
  display: none;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #191919;
}


.navbar .btn-secondary {
    background-color: #1196b8;
    border-color: #1196b8;
}


.navbar .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1196b8;
    border-color: #3ba8c4;
}
.navbar .dropdown-item.active[data-v-61dd7a3d], .dropdown-item[data-v-61dd7a3d]:active {
    color: #fff;
    text-decoration: none;
    background-color: #1196b8;
}
</style>
