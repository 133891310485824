import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import VueLazyload from 'vue-lazyload'
import VueCryptojs from 'vue-cryptojs'
import VueGtag from 'vue-gtag'
Vue.use(VueCryptojs)
// we import the vue router from our router/index.js file
import router from './router'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
// font awesome things
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretRight, faLanguage, faSync, faGamepad, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faFontAwesome, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
library.add(faCaretRight, faLanguage, faSync, faFontAwesome, faFacebookSquare, faGamepad, faSpinner)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)
const loadimage = require('./assets/lazy.gif')
const errorimage = require('./assets/lazy.gif')
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 3
})
//gtag
Vue.use(VueGtag, {
  config: {
    id: 'G-DCNRTBBEGK',
    params: {
      send_page_view: true
    }
  }
}, router)
// custom css file
//import "./assets/malluapps.css"
//import "./assets/malluapps.js"
//import "/express/index.js"

import $ from 'jquery';
window.$ = window.jQuery = require('jquery')
Vue.config.productionTip = false
Vue.prototype.$apiEndPoint = (Vue.config.productionTip) ? 'https://malluapps.com/api/ig/' : 'https://malluapps.com/api/ig/'

window.scrollToId = function (id, behavior) {
  var e = document.getElementById(id);
  window.scrollTo({
    top: e.offsetTop - 50,
    behavior: behavior || "auto",
  });
}
window.scrollToTop = function (behavior) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: behavior || "auto",
  });
}
var checkAndMount = setInterval((function () {
  if (window.FBInstant_found) { // FBInstant found (index.html)
    clearInterval(checkAndMount);
    window.loading_finished = true;
    FBInstant.setLoadingProgress(100);
    setTimeout(function () { // for showing 100% for eye blink speed
      FBInstant.startGameAsync().then((function () {
        new Vue({
          store,
          router,
          render: h => h(App),
          data() {
            return {
            }
          },
          methods: {
          },
          beforeCreate: function () {
            //console.log("Main - Before Create !")
            /*let fbinstant63 = document.createElement("script");
            fbinstant63.setAttribute(
              "src",
              "https://connect.facebook.net/en_US/fbinstant.6.3.js"
            );
            document.head.appendChild(fbinstant63);*/
          },
          created: function () {
            //console.log("Main - Created !")
          },
          beforeMount: function () {
            //console.log("Main - Before Mount !")
          },
          mounted: function () {
            //console.log("Main - Mounted !")
          },
        }).$mount('#app')
      }))
    }, 100);
  }
  else if (location.hostname == "localhost" && document.referrer != "https://www.facebook.com/") { // if local host dev
    clearInterval(checkAndMount);
    console.log("Developmet Server Found !") 
    window.loading_finished = true;
    new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  }
  else { //
    clearInterval(checkAndMount);
    console.log("Developmet Server Found !")
    window.loading_finished = true;
    new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  }
}), 500)