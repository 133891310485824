<template>
  <!-- Footer -->
  <div class="footer text-center">
    <div class="container">
      <p>
        <router-link to="/">Home</router-link>
        <span>|</span>
        <router-link to="/privacy">Privacy</router-link>
      </p>
      <p>
        Disclaimer : All content or apps are provided for fun and entertainment
        purposes only.
      </p>
      <p>© 2023 MalluApps</p>
    </div>
  </div>
  <!-- Footer End -->
</template>
<script>
export default {
  name: "Footer",
  props: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  background-color: #191919;
    color: #FFF;
    padding: 10px 0px 10px 0px;
    border-top: 1px solid #555555;
}
.container {
    padding-left: 0px;
    padding-right: 0px;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.footer a {
    color: #FFF;
    font-size: 12px;
    font-weight: bold;
     padding: 0 5px 0 5px;
}
.footer a:hover {
    color: gray;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
}
</style>
